
import BButton from 'buetify/lib/components/button/BButton';
import BSnackbar from 'buetify/lib/components/notices/snackbar/BSnackbar';
import { useToast } from 'buetify/lib/composables';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'template-snackbar-example',
	components: {
		BSnackbar,
		BButton
	},
	setup() {
		const toast = useToast();

		function onAction() {
			toast.open({
				variant: 'is-dark',
				message: 'Action Pressed',
				position: 'is-top',
				shouldQueue: false
			});
		}

		return {
			onAction
		};
	}
});
