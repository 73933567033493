
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import { ApiView } from '../../../../components/apiView';
import ExampleView from '../../../../components/exampleView/ExampleView.vue';
import { apis } from './apis';
import TemplateExample from './examples/TemplateExample.vue';
import TemplateExampleCode from '!!raw-loader!./examples/TemplateExample.vue';
import ProgrammaticExampleCode from '!!raw-loader!./examples/ProgrammaticExample.vue';
import ProgrammaticExample from './examples/ProgrammaticExample.vue';

export default defineComponent({
	name: 'snackbar-documentation',
	components: {
		ExampleView,
		ApiView,
		TemplateExample,
		ProgrammaticExample,
		BHorizontalDivider
	},
	setup() {
		return {
			apis,
			ProgrammaticExampleCode,
			TemplateExampleCode
		};
	}
});
